export const ability = `Type\tAbility\tDamage\tDPS
Arc\tArcbolt Grenade\t12858\t10287
Arc\tArc Soul\t58685\t29342
Arc:[ Getaway Artist ]\tArc Soul\t81506\t38813
Arc\tFlashbang Grenade\t14843\t11874
Arc\tFlux Grenade\t28254\t22603
Arc\tLightning Grenade\t47104\t37683
Arc\tPulse Grenade\t49765\t39812
Arc:[ Touch of Thunder ]\tPulse Grenade\t56053\t44843
Arc\tSkip Grenade\t13633\t10907
Arc\tStorm Grenade\t25835\t20668
Arc:[ Touch of Thunder ]\tStorm Grenade\t58951\t47161
Solar\tFirebolt Grenade\t7363\t5891
Solar\tFusion Grenade\t16431\t13145
Solar:[ Touch of Flame ]\tFusion Grenade\t33417\t26733
Solar\tGunpowder Gamble\t20275\t16220
Solar\tIncendiary Grenade\t19218\t15374
Solar\tSolar Grenade\t24038\t19231
Solar:[ Touch of Flame ]\tSolar Grenade\t57457\t45966
Solar\tSwarm Grenade\t21706\t17365
Solar\tThermite Grenade\t48067\t38453
Solar\tTripmine Grenade\t16271\t13017
Solar:[ Young Ahamkara's Spine ]\tTripmine Grenade\t18338\t14670
Stasis\tColdsnap Grenade\t4407\t3525
Stasis:[ Touch of Winter ]\tColdsnap Grenade\t8003\t6402
Stasis\tDuskfield Grenade\t12986\t10389
Stasis:[ Touch of Winter ]\tDuskfield Grenade\t16582\t13265
Stasis\tGlacier Grenade\t21658\t17326
Stasis:[ Touch of Winter ]\tGlacier Grenade\t25358\t20286
Strand\tGrapple\t25358\t20286
Strand\tShackle Grenade\t7067\t5653
Strand:[ Thread of Evolution ]\tThreadling Grenade\t18648\t14919
Void\tAxion Bolt\t10530\t8424
Void\tMagnetic Grenade\t17091\t13673
Void:[ Chaos Accelerant ]\tMagnetic Grenade\t28042\t12463
Void\tScatter Grenade\t17662\t14130
Void\tSuppressor Grenade\t15593\t12474
Void\tVoid Soul\t9481\t4741
Void\tVoid Spike\t44951\t35961
Void\tVoid Wall\t61879\t49503
Void\tVortex Grenade\t31532\t25226
Void:[ Chaos Accelerant ]\tVortex Grenade\t39524\t17566`;
