import {ability} from "../dps/abilityDps.js";
import {addArrowDown, addArrowUp, addCommas, addInfo, colorCells, sortRows} from "../utils.js";

const rows = ability.split('\n');
const headers = rows[0].split('\t').slice(1);
let currentSortColumn = null;
let currentSortOrder = null;
let maxValues = {
  "Damage": Number.NEGATIVE_INFINITY,
  "DPS": Number.NEGATIVE_INFINITY
};

let abilities = [];
function Ability(name, damage, castDps, backgroundColor, borderColor, info) {
  this.name = name;
  this.damage = damage;
  this.castDps = castDps;
  this.backgroundColor = backgroundColor;
  this.borderColor = borderColor;
  this.info = info;
}

const addRow = (rowData) => {
  const row = document.createElement('tr');
  let ability = new Ability("\t" + rowData[1], rowData[2], rowData[3]);
  for (let i = 1; i <= headers.length; i++) {
    const cell = document.createElement('td');
    if (i > 1 && i < 4) {
      cell.innerHTML = addCommas(rowData[i]);
    } else {
      cell.innerHTML = rowData[i];
    }
    if (i === 1) {
      const splitArray = rowData[0].split(':');
      const type = splitArray[0];
      const info = splitArray[1];
      if (info !== undefined) {
        addInfo(cell, info);
        ability.info = info;
      }
      if (type === 'Arc') {
        cell.style.backgroundColor = `rgb(183, 216, 232)`;
        ability.backgroundColor = "rgba(115, 201, 245, 0.2)";
        ability.borderColor = "rgb(115, 201, 245)";
      } else if (type === 'Solar') {
        cell.style.backgroundColor = `rgb(211, 193, 172)`;
        ability.backgroundColor = "rgba(217, 169, 111, 0.2)";
        ability.borderColor = "rgb(217, 169, 111)";
      } else if (type === 'Stasis') {
        cell.style.backgroundColor = `rgb(172, 182, 229)`;
        ability.backgroundColor = "rgba(103, 126, 224, 0.2)";
        ability.borderColor = "rgb(103, 126, 224)";
      } else if (type === 'Strand') {
        cell.style.backgroundColor = `rgb(178, 229, 172)`;
        ability.backgroundColor = "rgba(176,224,103,0.2)";
        ability.borderColor = "rgb(176,224,103)";
      } else {
        cell.style.backgroundColor = `rgb(201, 177, 211)`;
        ability.backgroundColor = "rgba(142, 77, 169, 0.2)";
        ability.borderColor = "rgb(142, 77, 169)";
      }
    } else {
      const numericValue = parseFloat(rowData[i]);
      if (!isNaN(numericValue)) {
        maxValues[headers[i - 1]] = Math.max(maxValues[headers[i - 1]], numericValue);
      }
    }
    row.appendChild(cell);
  }
  abilities.push(ability);
  document.querySelector('#ability-body').appendChild(row);
}

for (let i = 1; i < rows.length; i++) {
  addRow(rows[i].split('\t'));
}

const headersArray = [...document.querySelectorAll('#ability-table th')];
for (const header of headersArray) {
  header.style.cursor = 'pointer';
  header.addEventListener('click', (event) => {
    for (const header of headersArray) header.style.backgroundColor = null;
    event.target.style.backgroundColor = '#ddd';
    const headerIndex = headersArray.indexOf(event.target);
    if (headerIndex === currentSortColumn) {
      if (currentSortOrder === 'asc') {
        currentSortOrder = 'desc';
        event.target.appendChild(addArrowDown(headersArray, currentSortColumn));
      } else {
        currentSortOrder = 'asc';
        event.target.appendChild(addArrowUp(headersArray, currentSortColumn));
      }
    } else {
      currentSortColumn = headerIndex;
      currentSortOrder = 'desc';
      event.target.appendChild(addArrowDown(headersArray, currentSortColumn));
    }
    sortRows(currentSortColumn, 1, currentSortOrder, '#ability-body');
    sortGraph();
  });
}

colorCells('#ability-body', headers, maxValues);

function sortGraph() {
  let dpsData = {};

  const sortFunctions = [
    (a, b) => currentSortOrder === 'asc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name),
    (a, b) => currentSortOrder === 'desc' ? b.damage - a.damage : a.damage - b.damage,
    (a, b) => currentSortOrder === 'desc' ? b.castDps - a.castDps : a.castDps - b.castDps,
  ];

  const sortColumnData = [
    {label: 'Damage', key: 'damage'},
    {label: 'Damage', key: 'damage'},
    {label: 'Cast DPS', key: 'castDps'},
  ];

  abilities.sort(sortFunctions[currentSortColumn]);

  const {label, key} = sortColumnData[currentSortColumn];
  dpsData.label = label;
  if (label === 'Damage') {
    graph.options.scales.xAxes[0].scaleLabel.labelString = 'Damage';
  }  else {
    graph.options.scales.xAxes[0].scaleLabel.labelString = 'Damage Per Second';
  }

  dpsData.data = abilities.map(ability => ability[key]);
  dpsData.backgroundColor = abilities.map(ability => ability.backgroundColor);
  dpsData.borderColor = abilities.map(ability => ability.borderColor);
  dpsData.borderWidth = 1;

  graph.data.datasets = [dpsData];
  graph.data.labels = abilities.map(ability => ability.name);

  graph.update();
}

let dpsData = {
  label: "Damage",
  data: abilities.map(function(ability) {
    return ability.damage;
  }),
  backgroundColor: abilities.map(function(ability) {
    return ability.backgroundColor;
  }),
  borderColor: abilities.map(function(ability) {
    return ability.borderColor;
  }),
  borderWidth: 1,
};

const ctx = document.getElementById("ability-graph").getContext("2d");
const graph = new Chart(ctx, {
  type: "horizontalBar",
  data: {
    labels: abilities.map(function(ability) {
      return ability.name;
    }),
    datasets: [dpsData],
  },
  options: {
    responsive: true,
    aspectRatio: 1 / (rows.length / 25),
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      callbacks: {
        title: function(tooltipItems) {
          const ability = abilities[tooltipItems[0].index];
          return ability.info;
        },
        label: function(tooltipItems, data) {
          return `\t${data.datasets[0].label}: ${addCommas(tooltipItems.value)}`;
        }
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: "Damage",
        }
      }],
      yAxes: [{
        barPercentage: 1,
        categoryPercentage: 0.9,
        ticks: {
          mirror: true
        },
        scaleLabel: {
          display: true,
          labelString: "Ability",
        }
      }]
    }
  }
});
